import React from 'react'
import Wrapper from '../components/wrapper'

const AMPEmbed = ({ location }) => {
  return (
    <Wrapper location={location} title="AMP Embed | MedReps.com">
      <div className="container mx-auto">
        <iframe
          width={'100%'}
          height={10326}
          src="https://e.infogram.com/_/7UUo54BrtSPRwJ989KXt?src=embed"
        ></iframe>
      </div>
    </Wrapper>
  )
}
export default AMPEmbed
